import React from "react"
import { GatsbyImage, StaticImage, getImage } from "gatsby-plugin-image"
import { graphql, useStaticQuery } from "gatsby"
import { PrismicRichText } from "@prismicio/react"

import Layout from "../../components/layout/layout"
import SEOnew from "../../components/seo-new"
import htmlSerializer from "../../utils/htmlSerializer"
import CommitmentSignature from "../../components/sections/CommitmentSignature"
import useWindowSize from "../../utils/useWindowSize"
import HeroSectionWithTitle from "../../components/common/HeroSectionWithTitle"
import { reverse } from "lodash"

const ServicesPage = () => {
  const screen = useWindowSize()

  const data = useStaticQuery(graphql`
    query ServicesPageQuery {
      prismicServicesPage {
        data {
          title
          hero_image {
            gatsbyImageData(width: 960, imgixParams: { auto: "false" })
            alt
          }
          items {
            image {
              gatsbyImageData(width: 960, imgixParams: { auto: "false" })
            }
            service_title
            service_details {
              richText
            }
          }
          introduction {
            richText
          }
        }
      }
    }
  `)

  const doc = data.prismicServicesPage
  const image = getImage(doc.data.hero_image)

  return (
    <Layout>
      <SEOnew title={doc.data.title} />
      <HeroSectionWithTitle
        title="Services"
        description="Recognized nationally and internationally for the creative and
              functional artistry of their exceptional designs that are uniquely
              reflective of each client commissioning their work."
        image={image}
      />
      {/* <div
        className="w-full relative flex overflow-hidden h-300 bg-site-gray xl:rounded-bl-100"
      >
        <div className="flex w-full xl:w-1/2 h-full relative">
          <GatsbyImage
            image={image}
            alt={`Services`}
            style={{ width: "100%", height: "100%", filter: "brightness(1)" }}
            objectPosition={"top"}
          />
          <div
            className="absolute top-0 left-0 w-full h-full"
            style={{
              backgroundColor: "transparent",
              backgroundImage:
                screen.width > 640
                  ? "linear-gradient(to left,  #333333, #333333A0 10%, #FFFFFF00 35%)"
                  : "linear-gradient(to top, #333333, #33333310 50%, transparent 95%)",
            }}
          >
            &nbsp;
          </div>
        </div>
        <div className="w-full xl:w-1/2 pt-12 xl:pt-4 px-4 flex flex-col h-full justify-center absolute xl:static">
          <div className="w-full flex items-center justify-center pb-4">
            <span
              className="text-white text-center text-4xl tracking-widest"
              style={{ textShadow: "2px 2px 5px #333333" }}
            >
              Services
            </span>
          </div>
          <div
            className="p-1 text-white xl:text-site-yellow flex flex-col items-center text-sm lg:text-xl gap-y-4 px-2 md:px-4"
            style={{ textShadow: "1px 1px 20px black" }}
          >
            <p>
              Recognized nationally and internationally for the creative and
              functional artistry of their exceptional designs that are uniquely
              reflective of each client commissioning their work.
            </p>
          </div>
        </div>
      </div> */}

      <div className="w-full flex items-center justify-center flex-col mb-12">
        <div className="services-content w-11/12 lg:w-10/12 max-w-screen-lg flex flex-col md:items-center xl:items-start justify-center pt-4 lg:pt-8">
          <div>abc</div>
          <PrismicRichText
            field={doc.data.introduction.richText}
            components={htmlSerializer}
          />
          <div>cde</div>
          {doc.data.items.map((item, idx) => {
            const loc = idx % 2
            const image = getImage(item.image)
            return (
              <div
                key={`service-${idx}`}
                className={`lg:py-4 flex flex-col ${
                  loc === 0 ? "lg:flex-row" : "lg:flex-row-reverse"
                }`}
              >
                {image ? (
                  <div className="w-full lg:w-1/2 px-8 pb-8 relative">
                    <StaticImage
                      id="cover"
                      src="../../images/blank.png"
                      quality={95}
                      placeholder="DOMINANT_COLOR"
                      formats={["AUTO", "WEBP"]}
                      alt={item.title}
                      className="w-full h-full z-5 top-0 left-0"
                      objectFit="cover"
                      style={{position: "absolute"}}
                    />
                    <GatsbyImage
                      image={image}
                      alt={item.title}
                      class="w-full"
                    />
                  </div>
                ) : (
                  <div className="w-2/6">&nbsp;</div>
                )}
                <div
                  className={`w-full lg:py-4 ${
                    image ? "lg:w-1/2" : "lg:w-4/6"
                  } lg:px-4`}
                >
                  <p className="text-lg md:text-xl font-lato font-bold text-black">
                    {item.service_title}
                  </p>
                  <div className="lg:py-4">
                    <PrismicRichText
                      field={item.service_details.richText}
                      components={htmlSerializer}
                    />
                  </div>
                </div>
              </div>
            )
          })}
          {/* <ServiceAccordion items={doc.data.items} /> */}
        </div>
      </div>
      <CommitmentSignature />
    </Layout>
  )
}

export default ServicesPage
